@import "../node_modules/primeng/resources/themes/nova/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeicons/primeicons.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&display=swap");
@import "~ngx-toastr/toastr";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

body {
  background-color: #f5f5f5 !important;
  color: gray;
  font-family: "Roboto", sans-serif !important;
}

.subscriptionCall .p-dropdown .p-dropdown-panel {
  max-width: 178px !important;
  min-width: 150px !important;
}

.subscriptionCallABC .p-dropdown .p-dropdown-panel {
  max-width: 293px !important;
  min-width: 293px !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #015a06 !important;
}

.submitButton {
  background-color: #015a06 !important;
  width: 390px;
  height: 35px;
  box-shadow: 0px 3px 6px #d6d5df29;
  border-radius: 3px;
  color: white !important;
  margin-top: 0px !important;
  border: 1px solid #015a06;
}

span.p-button-icon.pi.pi-calendar {
  color: black;
}

.col-md-4.col-sm-4.col-xs-4.p-field p-dropdown .p-component {
  width: 100% !important;
  /* height: auto !important; */
  /* position: absolute; */
  /* left: -10px; */
  /* bottom: -15px; */
  top: -2px;
}

.col-md-4.col-sm-4.col-xs-4.p-field.pt-1 p-dropdown .p-component {
  width: 100% !important;
  /* height: auto !important; */
  /* position: absolute; */
  /* left: -10px; */
  /* bottom: -15px; */
  top: -2px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray !important;
  opacity: 1;
  /* Firefox */
}

.roleDrop {
  width: 489px !important;
}

:ng-deep.p-button.p-button-icon-only {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p-button.p-button-icon-only {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.vaiidate-field {
  border: 1px solid red !important;
  display: block !important;
  width: inherit !important;
  position: absolute !important;
  height: 30px !important;
}

.fullWidth {
  margin-right: 0px !important;
}

.validate-field {
  color: red;
}

label {
  color: gray;
}

.pDropdown {
  border: 1px solid gray;
  padding: 6px;
  border-radius: 5px;
  color: gray;
}

.p-dropdown {
  width: 100%;
  /* height: 35px; */
  width: 127px;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

/* .p-dropdown:hover {
    border: 1px solid #015A06 !important;
}
 */
.p-dropdown.p-component.p-dropdown-clearable {
  width: 130px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  /* color: #656565 !important; */
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: gray !important;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 14px;
}

body .p-datatable .p-datatable-tbody > tr {
  /* border: 1px solid #0000004D; */
  /* padding: 10px; */
}

#toast-container > div {
  opacity: 1;
}
div#toast-container {
  position: fixed !important;
  z-index: 99999999 !important;
}

.mr25 {
  margin-right: 25px;
}

.mt25 {
  margin-top: 25px;
}

.pt25 {
  padding-top: 25px;
}

.clearfix {
  clear: both;
}

.bgColor {
  background-color: white;
  border-radius: 18px;
}

.p-datatable .p-datatable-header {
  display: none !important;
}

.textElipis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width:1;
}

.sectionClass {
  margin-top: -30px;
  margin-left: -2%;
}

.p-datatable .p-datatable-thead > tr > th {
  border-width: 1px 0px 1px 0px !important;
  background: #e0ebe0 !important;
  font-size: 14px;
  font-weight: 500 !important;
  color: #000;
  height: 45px;
}

.p-datatable table {
  border: 1px solid #7070705c;
  /* border-width: 0px 1px !important; */
  margin-bottom: 25px;
}


tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
}

th,
thead {
  /* box-shadow: 5px 10px #888888 !important; */
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #0000004d;
  border-width: 0px 0px 1px 0px !important;
  padding: 0.515rem 0.857rem;
}

.p-datatable .p-datatable-tbody > tr > td {
  color: #656565;
  font-size: 14px;
}

::ng-deep.p-dropdown-panel.p-component {
  width: 100% !important;
}

ul.p-dropdown-items{
  padding-left: 0;
}

.p-dropdown-filter-icon{
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-element.p-icon-wrapper {
  color: black !important;
}
.p-dropdown-filter-container.ng-star-inserted {
  height: 33px;
}

.p-paginator.p-component.ng-star-inserted {
  border: none;
}

.p-paginator.p-component.ng-star-inserted {
  display: contents;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #e0ebe0 !important;
  color: #ffffff !important;
}

.p-dialog {
  border-radius: 15px !important;
}

.p-dialog .p-dialog-header {
  border-bottom: 0px solid #dedede !important;
  background: white !important;
  color: #3d0859 !important;
  font-style: normal !important;
  font-variant: normal !important;
  font-weight: normal !important;
  line-height: 24px !important;
  font-family: "Roboto" !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.p-dialog .p-dialog-content {
  padding: 1rem !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

/* .p-datatable-wrapper {
  padding: 0px !important;
} */

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  -ms-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  text-align: center;
  cursor: pointer;
  display: table;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  user-select: none;
  border: 1px solid #dedede;
  border-width: 1px;
  border-color: #dedede;
  background: #015a063d !important;
  color: #000 !important;
  padding: 0.857rem 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  font-weight: 500 !important;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -1px 0;
  width: 217px;
  height: 60px;
}

.p-tabview .p-tabview-nav li.p-highlight {
  background: #015a06;
  border-color: #015a06;
  color: #ffffff;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 0.571rem 1rem;
  border: 1px solid #dedede;
  color: #000;
  margin-top: 25px !important;
  border-radius: 10px !important;
}

.p-calendar-w-btn .p-datepicker-trigger {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  background: #80808021 !important;
  border: 1px solid gray !important;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

.toggleClass {
  color: #a19c9c;
  font-size: 14px;
  bottom: 10px;
  padding-left: 7px;
}

.dropClass {
  border-radius: 10px !important;
  font-size: 14px !important;
}

.required:after {
  content: " *";
  color: red;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #015a06 !important;
  background: #015a06 !important;
}

.p-checkbox .p-component {
  height: 14px !important;
  width: 14px !important;
}

.p-paginator {
  background-color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #015a06 !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  height: 30px !important;
  margin: 0px 5px !important;
  position: relative;
  top: -2px;
}

::ng-deep .p-paginator .p-paginator-pages .p-paginator-page {
  width: 30px !important;
  text-align: center !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #e0ebe0 !important;
  color: #808080 !important;
  height: 30px !important;
  width: 30px !important;
  margin: 3px !important;
  border-radius: 3px !important;
}

.p-paginator-prev .p-paginator-element .p-link .p-disabled .p-ripple {
  margin-left: 10px !important;
}

.p-paginator .p-paginator-current {
  color: #000 !important;
  float: right !important;
  margin: 3px 0 0 0 !important;
}

.p-dropdown-label .p-inputtext {
  margin-top: 15px !important;
}

.p-calendar-w-btn .p-inputtext {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: 30px !important;
}

.p-paginator-bottom {
  margin-top: 20px !important;
  font-size: 14px !important;
  border: 0px !important;
  display: block !important;
}

.alignmentLabel {
  display: contents !important;
  font-size: 14px !important;
}

.borderRadius1 {
    font-size: 14px;
    border-radius: 25px !important;
    padding-left: 40px !important;
    width: 100% !important;
    height: 35px !important;
    border: 1px solid #D1D1D1;
    border-radius: 30px !important;
    cursor: text !important;
}

.p-paginator .p-dropdown {
  width: 83px !important;
  height: 30px !important;
  padding-left: 5px !important;
  float: right !important;
  margin-top: 7px !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
  font-size: 14px;
  margin-top: 0px;
  color: #000;
  margin-left: 0;
}

.p-dropdown .p-dropdown-clear-icon {
  cursor: pointer !important;
}

.p-inputtext {
  height: 30px !important;
}

.p-inputtext {
  color: #656565 !important;
}

.p-dropdown-label {
  cursor: pointer;
  display: block;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 1%;
  padding: 5px !important;
}

h5 {
  font-size: 20px;
  color: var(--unnamed-color-3d0859);
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 24px;
  font-family: "Roboto";
  letter-spacing: 0px;
  color: #3d0859;
  margin-top: -13px;
  opacity: 1;
  font-weight: 500 !important;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0 #8dcdff !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0 #8dcdff !important;
}

.input-group .borderRadius {
  box-shadow: 0px 2px 20px #cfd9df66 !important;
}

.create1 {
  box-shadow: 0px 2px 20px #cfd9df66 !important;
}

ul.p-tabview-nav {
  padding-left: 0;
}

.selectPackage {
  width: 200px;
  height: 200px;
  background: #c3d8c3 0% 0% no-repeat padding-box;
  border-radius: 8px;
  text-align: center;
  opacity: 1;
  box-shadow: 0 0 10px 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #015a06 !important;
  border-color: #015a06 !important;
  color: #fff;
  font-size: 1em;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.p-calendar {
  border: none !important;
}

.p-button:focus {
  box-shadow: none !important;
}

input.form-control {
  padding-left: 3px !important;
}

.p-calendar .p-inputtext.p-component {
  padding-left: 3px !important;
  font-size: 16px;
}

.p-calendar .p-datepicker-trigger.p-button.p-component {
  max-height: 30px !important;
}

.p-dropdown-label.p-inputtext {
  padding-left: 0 !important;
}

.p-dropdown.p-component {
  padding-left: 3px !important;
}

.p-dropdown.p-component .p-dropdown-label.p-inputtext {
  padding-left: 0px !important;
  margin-left: 0 !important;
}

input.form-control:focus {
  border: 1px solid #d6d5df !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid #d6d5df !important;
  box-shadow: none !important;
}

select {
  outline: none !important;
  padding-left: 0px !important;
}

.p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: #a6a6a6 !important;
}

.swal2-styled.swal2-cancel:focus,
.swal2-confirm.swal2-styled:focus {
  box-shadow: none !important;
}

.marg-30m {
  margin-left: -30px;
}

.marg-20m {
  margin-left: -20px;
}

.marg-15m {
  margin-left: -15px;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #333333 !important;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

button:focus {
  box-shadow: none !important;
}

/* .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-radius: 0 !important;
    font-size: 10px !important;
} */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #015a06 !important;
  border-radius: 0 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.custom-btn-size {
  font-size: 18px;
}

::ng-deep .p-datepicker table td > span {
  width: 1.5rem !important;
  height: 2.5rem !important;
}
.p-datepicker table td {
  padding: 0 !important;
}

::ng-deep
  .p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year {
  padding: 0 !important;
}

::ng-deep .p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1rem;
}

@media (max-width: 768px) {
  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 16px !important;
  }

  p-paginator.p-element.ng-star-inserted {
    display: flex;
    flex-wrap: wrap;

  }

}
